* {
  font-family: 'Inter', sans-serif !important;
}
body{
  overflow: hidden !important;
}

.user-menu-dropdown {
  .ant-dropdown-menu-item {
    padding: 12px !important;
    .anticon {
      font-size: 16px;
    }
  }
}

.ant-menu-item:active, .ant-menu-submenu-title:active, .ant-menu-item-selected {
  //background-color: #997e15 !important;
  //background-color: #5522bb !important;
  color: inherit !important;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus,
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  color: #ffffff;
  font-size: 30px;
  height: 40px;
  width: 40px;
  background: rgba(0,0,0,0.6);
  border-radius: 5px;
  padding: 5px;
}

.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus,
.ant-carousel .slick-next{
  left: 94%;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-prev{
  left: 5px;
  z-index: 1;
}

.ant-modal {
  top: 50px;
}

.ant-carousel .slick-next::before,
.ant-carousel .slick-prev::before{
  content: '';
}

.ant-steps.ant-steps-horizontal {
  width: 50%;
  margin: auto;
}

#nprogress .bar {
  //background: linear-gradient(101.95deg, #ffa6cd -15.11%, #d15fd1 39.54%, #ad27d5 101.84%);
  background: #997e15;
  height: 3px;
}

#nprogress .peg {
  //box-shadow: 3px 3px 10px #d15fd1, 3px 3px 5px #ad27d5;
  box-shadow: 3px 3px 10px #997e15, 3px 3px 5px #997e20;
}